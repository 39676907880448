import { Component, ViewEncapsulation } from '@angular/core';

import { Platform, Events, ModalController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { AuthService, AppSettings, Utils, RSTracker, LogProvider, Store } from '../providers';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { HostListener, ElementRef} from '@angular/core';
import { of } from "rxjs";
import { delay, tap, filter } from "rxjs/operators";
import { FirebaseX } from '@ionic-native/firebase-x/ngx';
import { BackgroundMode } from '@ionic-native/background-mode/ngx';
import { IdlelPage } from '../pages/idle/idle';

import { ENV } from '../environments/environment';
import { DxpComponentService } from 'src/utils/provider/dxp.component.service';
import { ActionType } from 'src/providers/utils/enums';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['./app.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent {

  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  lastKey = 0;
  appScanValue = '';
  isShowIdle = false;

  constructor(
    public dxpComponentService: DxpComponentService,
    public store: Store,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private idle: Idle,
    private keepalive: Keepalive,
    private auth: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private appSettings: AppSettings,
    private el: ElementRef,
    private utils: Utils,
    private firebasex: FirebaseX,
    private events: Events,
    private tracker: RSTracker,
    private backgroundMode: BackgroundMode,
    public modalCtrl: ModalController,
    private log: LogProvider

  ) {
    this.initializeApp();
    this.appSettings.getSettingByName('IdleTime').subscribe(setting => {
      // tslint:disable-next-line:radix
      const timeIdle = +setting;
      idle.setIdle(timeIdle);
      idle.setTimeout(timeIdle);
      idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
      idle.onTimeout.subscribe(() => {
        this.idleState = 'Timed out!';
        this.timedOut = true;
        console.log('onTimeout ', this.idleState);
      });
      idle.onIdleEnd.subscribe(() => {
        this.idleState = 'No longer idle.';
        console.log('onIdleEnd ', this.idleState);
      });
      idle.onIdleStart.subscribe(() => {
        this.idleState = 'You\'ve gone idle!';
        console.log('AppComponent onIdleStart ', this.idleState);
        if (!this.isShowIdle) {
          this.emitEventDismissModal();
          this.modalCtrl.dismiss({
            'dismissed': true
          });
          this.utils.hideLoading();
          this.showIdleScreen();
        }
        this.auth.getCurrentUser().then(result => {
          this.auth.logout().then(() => {
            this.router.navigate(['signin']);
          });
        }).catch(() => {
          console.log("getCurrentUser doesn't exist!");
        });
        this.reset();
      });
      this.reset();
    });
    setInterval(function () {
      this.appScanValue = '';
    }.bind(this), 200);

    this.analytic();


    setInterval(() => {
      console.log('ping');
      this.ping();
    }, ENV.PingTime);
  }

  emitEventDismissModal() {
    const payload = {
      action: ActionType.DoCloseModal,
    };
    //pass data to dxp component
    window.postMessage({ ...payload }, '*');
  }

  /*
  @HostListener('input', ['$event','$event.target'])
    onEvent(event: KeyboardEvent,target: HTMLInputElement){
      var self = this;
      //this.control.viewToModelUpdate((target.value === '') ? null : target.value);
      console.log('AppModule listen KeyUp',event);
      //this.lastKey = event.timeStamp;
      //event.preventDefault();
      target.style.color = '#f3f3f3';
      var startLength =  target.value.length;
     
      var fakeObservable = of('dummy').pipe(delay(30));
      fakeObservable.subscribe(
         function () { 
          var endLength =  target.value.length;
          console.log('AppModule listen KeyUp startLength ',startLength);
          console.log('AppModule listen KeyUp endLength ',endLength);
          if(Math.abs(endLength - startLength) >= 2 )
          {
             //self.el.nativeElement.value = '';
            //this.control.viewToModelUpdate('');
            target.value='';
            //self.el.nativeElement.getElementsByClassName('native-input')[0].value=''
            
            //angular.element(target).scope().p
          }
          target.style.color = '#000000';
        });   
    }
  */

  @HostListener('document:keyup', ['$event', '$event.target'])
  handleKeyboardEvent(event: KeyboardEvent, target: HTMLInputElement) {
    var self = this;
    //var source =  Rx.Observable.prototype.delay(20);
    //source.subscribe(
    //    function () { console.log('AppModule, Input KeyUp: ' ,event); }
    //)
    if (target && target.nodeName === 'INPUT') {
      //console.log('AppModule listen KeyUp event ',event);
      //console.log('AppModule listen KeyUp target ',target);
      if (this.appScanValue.length >= 1) {
        target.style.color = '#f3f3f3';
      }
      var startLength = target.value.length;

      var fakeObservable = of('dummy').pipe(delay(30));
      fakeObservable.subscribe(
        function () {
          //var endLength =  target.value.length;
          //console.log('AppModule listen KeyUp startLength ',startLength);
          //console.log('AppModule listen KeyUp endLength ',endLength);
          //if(Math.abs(endLength - startLength) >= 2 )
          //{
          //angular.element(event.target);
          //this.model.valueAccessor.writeValue(event);
          //this.model.valueAccessor.writeValue('');

          //this.previousValue = event.value;
          //event.target.setValue('');
          //target.value="";

          //var tmp = document.createElement("input");
          //document.body.appendChild(tmp);
          //tmp.focus();
          //document.body.removeChild(tmp);
          //target.value="";
          //  event.preventDefault();
          //this.change.emit(event);

          // this.control.viewToModelUpdate('');
          //}
          setTimeout(function () {
            target.style.color = '#000000';
          }, 300);
        });
      this.appScanValue += event.key;
    }
  }

  initializeApp() {
    this.tracker.init(ENV.MatomoCfg);
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();

      this.backgroundMode.enable();

      var deviceName = '';
      if ((window as any).device) {
        deviceName = (window as any).device.name;
      }

      var storeID = 1;
      if (deviceName) {
        // let segs = deviceName.split(/,|-/);
        // let storeNameTmp = '';
        // if (segs.length > 1) {
        //   storeNameTmp = segs[1].trim().replace('Store', '');
        // }
        // storeID = '' + (parseInt(storeNameTmp) || 0);
        
        //DeviceName: Store126iPad02
        storeID = parseInt(deviceName.slice(5,8));
      }

      ENV.DeviceName = deviceName;
      ENV.StoreId = storeID;

      this.tracker.trackUserProperty(1, storeID);
      this.tracker.trackUserProperty(2, deviceName);
    });

    // analytic
    let navigationO = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      tap((event: any) => {
        try {
          // track page Event
          let state = this.route.root;
          //Get Page name
          while (state.firstChild) {
            state = state.firstChild;
          }
          const componentName = <any>state.component && (<any>state.component).name || '';
          console.log('component', componentName, event.url); // Log to check when build prod

          this.tracker.trackPage(componentName, event.url);
        } catch (error) {
          console.error('Tracking page', error); // Log to check when build prod
        }

      })
    );
    navigationO.subscribe();
    // end analytic
  }

  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }
  analytic() {
    this.events.subscribe('authChange', (user: any) => {
      if (user) {
        this.firebasex.setUserId(user.UserID);
        this.firebasex.setUserProperty('MemberID', user.ExternalCustomerCardID);
        this.firebasex.setUserProperty('HomeStore', user.StoreID);

        this.firebasex.setCrashlyticsUserId(user.UserID);

        this.tracker.trackUser(user.UserID);
        // this.tracker.trackUserProperty(1, user.StoreID);
      }
      else {
        this.firebasex.setUserId(null);
        this.firebasex.setUserProperty('MemberID', null);
        this.firebasex.setUserProperty('HomeStore', null);
        this.firebasex.setCrashlyticsUserId(null);
      }
    })
    //  analytic
    let navigationO = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      tap((event: NavigationEnd) => {
        this.firebasex.setScreenName(event.url);
      })
    );
    navigationO.subscribe();
  }

  ping() {
    this.tracker.trackEvent('system', 'ping', null, 1);
  }
  
  async showIdleScreen() {
    this.isShowIdle = true;
    const m = await this.modalCtrl.create({
      component: IdlelPage,
      cssClass: 'idle-modal'
    })
    await m.present();
    const rs = await m.onDidDismiss();
    this.isShowIdle = false;
  }

  @HostListener('window:message', ['$event'])
  async listenDxpComponent(event) {
    try {
      this.dxpComponentService.listenDxpComponent(event);
    } catch (error) {
      console.error('error listenDxpComponent', error)
    }
  }
}
