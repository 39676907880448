import { Api } from '../api/api';
import 'rxjs/add/operator/toPromise';

import { Injectable } from '@angular/core';
import { CacheService } from 'ionic-cache';
import { Observable } from 'rxjs';
import { Observer } from 'rxjs/Observer';
import * as _ from 'lodash';
import { ENV } from '../../environments/environment';

@Injectable()
export class Offer {
	dxpApiUrl: any;

	constructor(public api: Api, private cache: CacheService) {
		this.dxpApiUrl = 'dxp/api';
	}

	getAllOffer(user, includedExpired, programCode, refresher = true) {
		const self = this;
		const url = 'offers';
		// return req;

		var reqUrl = `${this.dxpApiUrl}/offer/GetAvailableOffers?customerCode=${user.SRCardID}&storeId=${user.StoreID}&cardId=${user.ExternalCustomerCardID}&programCode=${programCode}`;
		const funcRespone = function (obs: Observer<any>) {
			// tslint:disable-next-line:max-line-length
			// const req = self.api.get('srcoupons?cpn=Click2Card&includedExpired=' + includedExpired + '&cardid=' + user.SRCardID, null, null, true);
			const req = self.api.get(reqUrl, null, null, true);
			self.cache.removeItem(url);
			self.cache.loadFromObservable(url, req, url, 3600).subscribe(result => {
				obs.next(result);
				obs.complete();
			});
		}
		return new Observable((obs: Observer<any>) => {
			this.cache.getItem(url).then(result => {
				if (result && !refresher) {
					obs.next(result);
					obs.complete();
				} else {
					funcRespone(obs);
				}
			}).catch((error) => {
				funcRespone(obs);
			});
		});
	}

	addPromoCode(user, promoCode) {
		const self = this;
		const req = self.api.post(`${this.dxpApiUrl}/coupon/click2card/promoCode/v4?promoCode=${promoCode}&cardId=${user.SRCardID}`, {});
		return req;
	}

	validatePromoCode(promoCode, storeId = 1, bannerId = 30) {
		const self = this;
		const req = self.api.get(`${this.dxpApiUrl}/coupon/by/promocode/${promoCode}/store/${storeId}/banner/${bannerId}?isNewUser=true`, null, null, false, true);
		return req;
	}

	getFuelGas(user, memberAccount: string, includeTrans: boolean) {
		const self = this;
		const req = self.api.get(`${this.dxpApiUrl}/cards/${user.SRCardID}/point?memberAccount=${memberAccount}&includedTrans=${includeTrans}`);
		return req;
	}
}
