export abstract class EnvironmentModel {
   // public abstract read mode?: "string";
	public abstract readonly Mode: string;
	public abstract readonly APIUrl: string;
	public abstract readonly APIDetectUrl: string;
	public abstract readonly APIComsumerKey : string;
	public abstract readonly APIUserName : string;
	public abstract readonly APIPassword : string;
	public abstract readonly EComHost: string;
	public abstract readonly ScriptUrl: string;
	public DxpComponentBuildNumber: string;
	
  	//Links
	public readonly links = {
		supportContact: 'https://www.bigy.com/myBigYsupport',
		locations: 'https://www.bigyexpress.com/Locations',
		tc: 'https://www.bigy.com/TermsConditions',
		privacy: 'https://www.bigy.com//Services/Policies'
	};

	public abstract readonly production : boolean;
	public readonly AppVersion = '1.1.10';
	public readonly BuildNumber = '1110001';
	public readonly TrackingCode = isAndroid(window) ? 'BigYTablet' : 'BigYiPad';
	public readonly HeaderToken = 'X-MCMAccessToken';
	public readonly DefaultHeaders = { HeaderToken: "",'Content-Type': 'application/json',"TrackingCode":this.TrackingCode };
	public readonly DefaultBanerId = 30;
	public readonly Pincode = 2020;
	public readonly PingTime = 5 * 60000;
	public DeviceName = '';
	public StoreId = 1; //Default 1

	public readonly AppBundle = "com.bigy.tablet";
	public readonly EnableMatomo = true;
	public readonly MatomoCfg = {
		mUrl: 'https://track1.relationshop.net/',
		mId: 2,
		installedGoal: 1
	};
	public abstract readonly DebugMode: boolean;
	public abstract readonly DebugMail: string;

	public readonly DefaultImg: string = 'https://unitedcloud.relationshop.net/RSData/DefaultMissingImage.jpg';
}

/* copy code from https://github.com/ionic-team/ionic/blob/master/core/src/utils/platform.ts */

export const testUserAgent = (win: Window, expr: RegExp) =>
  expr.test(win.navigator.userAgent);

const isAndroid = (win: Window) =>
  testUserAgent(win, /android|sink/i);

/* end copy code */