import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthService, Store } from "src/providers";
import { RelationshopEComHttpClient } from "src/providers/api/rs-ecom-api.service";
import { AProduct } from "./aproduct.service";
import _ from 'lodash';
import { of, Observable, from } from 'rxjs';
import { map, tap, switchMap, take } from "rxjs/operators";
import { refreshParam } from "src/providers/utils/utils";
import { RelationshopHttpClient } from "src/providers/api/rs-api.service";

@Injectable()
export class RSProductService extends AProduct {
    groupCacheKey = 'rs-product';
    prefixCacheKey = 'rs-online';

    scrsEcomApiUrl: any;
    currentUser: any;
    currentStore: any;

    constructor(
        public api: RelationshopEComHttpClient,
        public auth: AuthService,
        public store: Store,
        public rsApi: RelationshopHttpClient,
    ) {
        super(api, auth, store, rsApi);
        this.scrsEcomApiUrl = `scrsecom/v1.0/api`;
    }

    search(cri: {
        store?: any,
        keyword?: string,
        deptIds?: any,
        subDept1Id?: [],
        subDept2Id?: [],
        brandIds?: any,
        savings?: any,
        tags?: any,
        onSale?: boolean,
        sort?: any,
        upc?: any,
        upcList?: any,
        itemId?: any,
        checkSumUpc?: boolean,
        pageSize: number,
        pageIndex: number,
        customFields?: any,
        primaryBarcode?: any,
        attrs?: any
    }, cache?: {
        rCache?: any,
        rCacheKey?: any,
        rCacheGroup?: any,

    }) {

        if (!cri.store) {
            console.log('asdfaf');
            cri.store = this.currentStore;
        }

        let params = {
            ExternalStoreID: cri.store && cri.store.ExternalID || '',
            StoreID: cri.store.ExternalID2,
            Keyword: cri.keyword || '',
            PageSize: cri.pageSize,
            PageNum: cri.pageIndex,
            DepartmentId: [],
            UPCList: cri.upcList,
            ItemId: cri.itemId,
            CheckSumUPC: cri.checkSumUpc,
            SortOrder: [],
            BrandName: cri.brandIds,
            SavingsName: cri.savings,
            CustomFields: [],
            PrimaryBarcode: cri.primaryBarcode || true,
            SubDept1Id: [],
            SubDept2Id: []
        };

        // handle dept for pass to DepartmentId, SubDept1Id, SubDept2Id
        _.each(cri.deptIds, (deptId: string) => {
            let deptIds = deptId.split('-');
            if (deptIds[0]) {
                params.DepartmentId.push(deptIds[0])
            }
            if (deptIds[1]) {
                params.SubDept1Id.push(deptIds[1])
            }
            if (deptIds[2]) {
                params.SubDept2Id.push(deptIds[2])
            }
        });

        // handle sort
        if (cri.sort) {
            const sortStrs = cri.sort.split(' '),
                direction = sortStrs[1] || 'DESC';
            let fieldName = sortStrs[0] && sortStrs[0].toLowerCase() || '';

            let sortObj = null;
            if (!fieldName) {
                fieldName = "bestmatch";
            }
            if (this.prefixCacheKey == "instore") {
                switch (fieldName) {
                    case "price":
                        sortObj = {
                            position: 1,
                            sortColumn: 'regPrice',
                            sortDirection: direction
                        };
                        break;
                    case 'onsale':
                        sortObj = {
                            position: 1,
                            sortColumn: 'tprPrice',
                            sortDirection: direction
                        };
                        break;
                    case 'brand':
                        //Brand A-Z
                        sortObj = {
                            position: 1,
                            sortColumn: 'nameForSales',
                            sortDirection: direction
                        };
                        break;
                }
            } else {
                switch (fieldName) {
                    case "bestmatch":
                        sortObj = {
                            sortColumn: 'Relevance',
                        };
                        break;
                    case "price":
                        sortObj = {
                            sortColumn: (direction == "DESC" ? 'UnitPrice' : 'Price'),
                        };
                        break;
                    case 'onsale':
                        sortObj = {
                            position: 1,
                            sortColumn: 'tprPrice',
                            sortDirection: direction
                        };
                        break;
                    case 'brand':
                        sortObj = {
                            sortColumn: 'Brand',
                        };
                        break;
                }
            }
            if (sortObj) {
                params.SortOrder = [sortObj];
            }
        }
        // handle healthFocus
        if (cri.attrs && cri.attrs.length > 0) {
            params.CustomFields = _.map(cri.attrs, (attr) => { return { fieldId: attr, fieldValue: 'Y' } });
        }
        let opts = undefined;
        if (cache) {
            opts = { params: cache };

        }
        return this.api.post(`${this.scrsEcomApiUrl}/Product/search`, params, opts).pipe(
            tap((rs: any) => {
                // console.log(rs);
                if (rs && rs.Products) {
                    _.each(rs.Products, this._subplmentProductInfo);
                }
            })
        );
    }

    getProductsByIds(ids, store?) {
        return this.getProductsByUpcs(ids, store);
    }

    getProductsByUpcs(upcs, store?) {
        // if (!store) {
        //     store = this.currentStore;
        // }
        let params = {
            StoreId: store && store.ExternalID,
            Upcs: upcs,
            UpcsPerCall: 200
        }
        let rs:Observable<any> = this.getCurrentStore();
        return rs.pipe(
            switchMap((store)=>{
                params.StoreId = store && store.StoreID + "";
                return this.api.post(`${this.scrsEcomApiUrl}/Product/search/by-upcs`, params)
            }),
            map((items: any) => {
                if (!items) {
                    return { Products: [] };
                }
                _.each(items, this._subplmentProductInfo);
                return { Products: items };
            })
        );
    }

    _subplmentProductInfo(product) {
        const productVariantName = (product.ProductVariants && product.ProductVariants.length > 0)
            ? product.ProductVariants[0].Name : product.Name;
        if (!product.Sizes || product.Sizes.length === 0) {
            if (product.ProductVariants && product.ProductVariants.length > 0) {
                const defaultVariant = product.ProductVariants[0];
                Object.assign(product, {
                    ItemKey: defaultVariant.Id || defaultVariant.Sku,
                    ProductVariantName: productVariantName
                });
                delete product.ProductVariants;
            }
            return;
        }
        product.ProductVariants = _.map(product.Sizes, (size, index) => {
            return {
                UPC: product.UPC,
                Size: size.Size,
                ItemKey: size.ItemKey || product.Id,
                Id: product.Id,
                ItemSize: size.Size,
                PriceText: product.PriceText,
                Name: productVariantName
            }
        })
        product.isLoaded = true;
    }

    getProductByUPC(storeId, pageSize, pageNum, upcList, primaryBarcode = null, subDept1Id?, subDept2Id?, sortBy?, filters?, indexes?, checkUpc?) {
        // var store = this.currentStore
        let params = {
            storeId: null,
            upc: upcList[0]
        }
        let rs:Observable<any> = this.getCurrentStore();
        
        return rs.pipe(
            switchMap((store)=>{
                params.storeId = (store && store.StoreID) || (this.currentStore && this.currentStore.StoreID);

                return this.api.get(`${this.scrsEcomApiUrl}/Product/get-by-upc`, params)
            }),
            map((rs: any) => {
                if (!rs) {
                    return null;
                }
                const data = {
                    Products: [
                        rs
                    ]
                }
                _.each(data.Products, this._subplmentProductInfo);
                return data;
            })
        );
    }

    getDepartments(refresher?) {
        refresher = true;
        let params = {};
        Object.assign(params, refreshParam(refresher, this.groupCacheKey, null, this.prefixCacheKey));

        return this.rsApi.get('/searchcategories/29', { params: params }).pipe(
            map((rs) => {
                let data = _.filter(rs, (i) => {
                    return (!i.IsDeleted) && i.Active;
                })
                return _.orderBy(data, [
                    // i => !!i.Priority,
                    i => {
                        return i.Priority ? i.Priority : 1000
                    }
                ], ['asc']);
            })
        );
    }

    getHealth(product) {
        return this.api.get(`${this.scrsEcomApiUrl}/product/nutrition`, { params: { upc: /*'0793216041210' || */product.UPC }, responseType: 'text' });
    }
    getCurrentStore(){
        return from(this.auth.getCurrentUser()).pipe(
            switchMap((currentUser:any)=>{
                this.currentUser = currentUser;
                if(this.currentStore && this.currentStore.StoreID == currentUser.StoreID){
                    return of(this.currentStore);
                }
                return this.store.getStoreById(currentUser.StoreID)
            }),
            tap((store: any)=>{
                this.currentStore = store;
            })
        );
    }
}