export enum AccountType{
    Personal = "Personal",
    Business = "Business"
}

export enum Segment{
    NonProfit = 6,
    BusinessChecks = 7
}

export enum ActionType {
    DoCloseModal = 'DoCloseModal',
}