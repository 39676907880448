import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { Utils, Api, AuthService, RequestCache, TokenInterceptor, Store, User, AppSettings, LogProvider } from '../providers';
import { MessagePopupModule } from '../pages/modal-popup/message-popup/message-popup.module';
import { ConfirmPopupModule } from '../pages/modal-popup/confirm-popup/confirm-popup.module';
import { AddOfferPromoCodePopupModule } from '../pages/modal-popup/add-offer-promo-code-popup/add-offer-promo-code-popup.module';
import { AlertPopupModule } from '../pages/modal-popup/alert-popup/alert-popup.module';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

import { Geolocation } from '@ionic-native/geolocation/ngx';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CacheModule } from 'ionic-cache';
import { IonicStorageModule } from '@ionic/storage';
import { OfferDetailPopupModule } from '../pages/modal-popup/offer-detail-popup/offer-detail-popup.module';
import { DigitalCouponDetailPopupModule } from '../pages/modal-popup/digital-coupon-detail-popup/digital-coupon-detail-popup.module';
import { TermsConditionsPopupModule } from '../pages/modal-popup/terms-conditions-popup/terms-conditions-popup.module';
import { PrivacyPolicyPopupModule } from '../pages/modal-popup/privacy-policy/privacy-policy-popup.module';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { File } from '@ionic-native/file/ngx';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { FirebaseX } from '@ionic-native/firebase-x/ngx';
import { Device } from '@ionic-native/device/ngx';
import { MatomoModule } from 'ngx-matomo';
import { SetStorePopupModule } from '../pages/modal-popup/set-store-popup/set-store-popup.module';
import { HTTP } from '@ionic-native/http/ngx';
import { BackgroundMode } from '@ionic-native/background-mode/ngx';
import { IdlelPage } from '../pages/idle/idle';
import { Network } from '@ionic-native/network/ngx';
import { EmailComposer } from '@ionic-native/email-composer/ngx';
import { DatePipe } from '@angular/common';
import { NgxPubSubModule } from '@pscoped/ngx-pub-sub';
import { SafeUrlPipe } from '../providers/utils/pipes/safe-html/safe-url';
import { SafeResourceUrlPipe } from '../providers/utils/pipes/safe-html/safe-resource-url';
@NgModule({
  declarations: [AppComponent, IdlelPage, SafeUrlPipe, SafeResourceUrlPipe],
  entryComponents: [IdlelPage],
  imports: [BrowserModule, IonicModule.forRoot(), CacheModule.forRoot(), IonicStorageModule.forRoot(), NgIdleKeepaliveModule.forRoot(),
    // tslint:disable-next-line:max-line-length
    NgxPubSubModule,
    AppRoutingModule, OfferDetailPopupModule, DigitalCouponDetailPopupModule, MessagePopupModule, SetStorePopupModule, ConfirmPopupModule, AddOfferPromoCodePopupModule, AlertPopupModule, TermsConditionsPopupModule, PrivacyPolicyPopupModule, HttpClientModule, MatomoModule],
  providers: [
    StatusBar,
    SplashScreen,
    InAppBrowser,
    Geolocation,
    AppSettings,
    File,
    BarcodeScanner,
    FirebaseX,
    Device,
    HTTP,
    BackgroundMode,
    Network,
    EmailComposer,
    DatePipe,
    LogProvider,
    Utils, Api, RequestCache, TokenInterceptor, AuthService, Store, User,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

}
