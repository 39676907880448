import { User } from './../user/user';
import { Injectable } from '@angular/core';
import { ENV } from '../../environments/environment';
import { Api } from '../api/api';
import { Utils } from '../utils/utils';
import { Storage } from '@ionic/storage';
import { CacheService } from 'ionic-cache';
import { Router } from '@angular/router';
import { Events, ModalController } from '@ionic/angular';
import { AlertPopup } from 'src/pages/modal-popup/alert-popup/alert-popup';
import { ReplaySubject } from 'rxjs';
import { NgxPubSubService } from '@pscoped/ngx-pub-sub';

@Injectable({
	providedIn: 'root'
})
export class AuthService {
  public currentUser$: ReplaySubject<any>;
  dxpApiUrl: any;

  constructor(
    private api: Api,
    public storage: Storage,
    private cache: CacheService,
    private router: Router,
    private utils: Utils,
    private events: NgxPubSubService,
    private modalCtrl: ModalController
  ) {
    this.currentUser$ = <ReplaySubject<any>>new ReplaySubject(1);
    this.dxpApiUrl = 'dxp/api';
  }

  getAuthToken(): string {
    const currentUser = JSON.parse(localStorage.getItem('CurrentApiUser'));
    if (currentUser != null) {
      return currentUser.AccessToken;
    }

    return '';
  }

  refreshToken() {
    const tokenRequest = {
      Username: ENV.APIUserName,
      Password: ENV.APIPassword
    };
    return this.api.post(`${this.dxpApiUrl}/tokens/${ENV.APIComsumerKey}`, tokenRequest);
  }

  getCurrentUser() {
    const key = 'cache-user';
    return this.cache.getItem(key);
  }

  updateCurrentUser(user: any) {
    return new Promise((resolve, reject) => {
      this.cache.saveItem('cache-user', user, undefined, 60 * 60 * 12 * 15).then((data) => {
        resolve(data);
      });
    });
  }

  login(accountInfo: any, isSignup = false) {
    return new Promise((resolve, reject) => {
      this.api.post(`${this.dxpApiUrl}/login/${accountInfo.username}`, '"' + accountInfo.password + '"').subscribe((data: any) => {
        resolve(data);
        if (data.IsSuccess) {
          data.User.AccessToken = data.Token;
          localStorage.setItem('CurrentApiUser', JSON.stringify(data.User));
          this.currentUser$.next(data.User);
          console.log('sala ---------------------------', 'publish data.User');
          this.events.publishEvent('authChange', data.User);
          this.cache.saveItem('cache-user', data.User, undefined, 60 * 60 * 12 * 15).then(() => {
            if (data.User.IsPwdTemporary) {
              this.utils.hideLoading();
              this.router.navigate(['changePassword']);
            }
            else {
              if (isSignup) {
                this.openPopupSignupSuccess();
              } else {
                this.router.navigate(['/']);
              }

            }
          });
        }
      },
        (error) => {
          console.error('ERROR', error);
          reject(error);
        });
    });
  }

  logout() {
    this.events.publishEvent('authChange', null);
    console.log('logout');
    this.utils.segmentContent = '';
    this.utils.pageSlideDigitalCouponIndex = 0;
    this.utils.pageSlideOfferCouponIndex = 0;
    localStorage.clear();
    window.postMessage({ action: 'userLogOut' }, '*');
    return this.cache.clearAll() && this.storage.clear();
  }

  async openPopupSignupSuccess() {
    this.utils.hideLoading();

    const modal: HTMLIonModalElement = await this.modalCtrl.create({
      component: AlertPopup,
      componentProps: {
        title: 'Success!',
        message: 'Your myBigY Account <br> has been created.'
      },
      // tslint:disable-next-line:quotemark
      cssClass: "alert-popup auto-height"
    });
    modal.onDidDismiss().then((data) => {
      console.log('data', data);
      this.router.navigate(['/']);
    });
    await modal.present();

    setTimeout(() => {
      modal.dismiss();
    }, 3000);

  }

}

